import axios from "axios";
import {
    config,
    Module,
    VuexModule,
    Mutation,
    Action
} from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "image";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

@Module({
    dynamic: true,
    store: Store,
    name: "ImageModule",
    namespaced: true
})
export default class ImageModule extends VuexModule {
    imageFile: File | string = "";
    imageFilename = "";
    imageFileUrl = "";

    @Mutation
    public setImageFile(data: File | string) {
        this.imageFile = data;
    }

    @Mutation
    public setImageFilename(data: string) {
        this.imageFilename = data;
    }

    @Mutation
    public setImageFileUrl(data: string) {
        this.imageFileUrl = data;
    }

    @Action({})
    async getImage(guid: string) {
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(`${endpoint}`, {
                headers: {
                    Authorization: `bearer ${token}`
                },
                params: {
                    id: guid
                }
            });

            return image.data;
        } catch (error) {
            throw new Error("Could not get Image url.");
        }
    }

    @Action({ rawError: true })
    async uploadImage(options: Record<string, string | File>) {
        const token = this.context.rootGetters.idToken;

        let signedUrlResponse = { id: "", url: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            const uploadResponse = await getApiClient().get(
                `${endpoint}-upload`,
                {
                    params: {
                        contentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );

            signedUrlResponse = uploadResponse.data;
        } catch (error) {
            throw new Error("Could not get Image put url");
        }

        try {
            await axios.request({
                url: signedUrlResponse.url,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("Image upload failed.");
        }

        return signedUrlResponse.id || "";
    }
}
