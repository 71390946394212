






















































































import { getModule } from "vuex-module-decorators";
import Vue from "vue";
import ComapanyModule from "@/store/vuex-modules/companies";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const companyStore = getModule(ComapanyModule);

export default Vue.extend({
    data() {
        return {
            isLoading: false,
            submitSucess: false,
            submitError: false
        }
    },
    components: { Spinners },
    computed: {
        heroImage(): string | File {
            return companyStore.heroImage;
        },

        heroFileName(): string | File {
            return companyStore.heroFileName;
        }
    },
    //life cycle
    created() {
        eventHub.$on("exhibitor-hero-upload-started", this.handleUpdateStarted);
        eventHub.$on("exhibitor-hero-upload-success", this.handleUpdateSuccess);
        eventHub.$on("exhibitor-hero-upload-error", this.handleUpdateError);
        eventHub.$on("exhibitor-hero-upload-done", this.handleUpdateDone);
    },
    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off(
            "exhibitor-hero-upload-started",
            this.handleUpdateStarted
        );
        eventHub.$off(
            "exhibitor-hero-upload-success",
            this.handleUpdateSuccess
        );
        eventHub.$off("exhibitor-hero-upload-error", this.handleUpdateError);
        eventHub.$off("exhibitor-hero-upload-done", this.handleUpdateDone);
    },
    methods:{
    //methods
        resetFileSelect() {
            companyStore.setCompanyHeroImage("");
            companyStore.setHeroImageFileName("");
        },
        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSucess = false;
            this.submitError = false;
        },
        handleCancel() {
            this.resetFileSelect();
            this.$emit("cancel");
        },
        handleSubmit() {
            if (!this.heroFileName) return;
            this.$emit("submit");
        },
        handleUpdateStarted() {
            this.isLoading = true;
        },
        handleUpdateSuccess() {
            this.resetFileSelect();
            this.submitSucess = true;
        },
        handleUpdateError() {
            this.submitError = true;
        },
        handleUpdateDone() {
            this.isLoading = false;
        },
        fileSelect(e: any) {
            const el = e.target;
            const files = el.files;
            let fileName = "";
            let image = "";

            if (files && 0 < files.length) {
                fileName = files[0].name;
                companyStore.setHeroImageFileName(fileName);
            } else {
                this.resetFileSelect();
                return;
            }

            switch (files[0]["type"]) {
                case "image/jpeg":
                case "image/jpg":
                case "image/png":
                    image = files[0];
                    companyStore.setCompanyHeroImage(image);
                    break;
                default:
                    alert("Sorry that file type is not allowed.");
                    this.resetFileSelect();
                    break;
            }
        }
    }
})
