



























import Vue from "vue"
import { TradeshowVideoObject } from "@/types/interfaces";


export default  Vue.extend({
    props: {
        sessions:{
            required: true,
            type:Array,
            default() {
                return [] as any

                // return [] as TradeshowVideoObject 
            }
        }
    },
    // this is a temp filter
    computed:{
        sessionList(): Array<TradeshowVideoObject> {
            //TODO: Restore when in vue 3, interfaces propertieas are not infered on this syntax
            // return this.sessions.filter((session: TradeshowVideoObject) => { 
            return this.sessions?.filter((session: any) => {
                return session.videoName != "";
            });
        }
    },
    methods:{
        navigateToSession(url: string) {
            window.open(url);
        },

        getThumbnail(url: string) {
            if (url) {
                const youtubeId = url.split("/watch?v=").pop();
                return `https://img.youtube.com/vi/${youtubeId}/3.jpg`;
            }
            return "";
        }
    }
})
