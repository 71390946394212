import Vue from "vue";
import axios from "axios";
import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";
import {
    TradeshowCompanyObject,
    GetCompanyDataOptions
} from "@/types/interfaces";

import attendeeSelectionVuexModule from "@/store/vuex-modules/attendeeSelection";
const attendeeSelectionStore = getModule(attendeeSelectionVuexModule);

// This module is only for functionality related to the following endpoint:
const endpoint = "companies";

@Module({
    dynamic: true,
    store: Store,
    name: "CompanyModule",
    namespaced: true
})
export default class CompanyModule extends VuexModule {
    companyData: TradeshowCompanyObject = {};
    companyUpdatePayload: TradeshowCompanyObject = {};
    companyResetData: Partial<TradeshowCompanyObject> = {};

    logoImage: File | string = "";
    logoFileName = "";

    heroImage: File | string = "";
    heroFileName = "";

    meetingLogoImage: File | string = "";
    meetingLogoFileName = "";

    @Mutation
    public setCompanyLogoImage(data: File | string) {
        this.logoImage = data;
    }
    @Mutation
    public setLogoImageFileName(data: string) {
        this.logoFileName = data;
    }

    @Mutation
    public setCompanyHeroImage(data: File | string) {
        this.heroImage = data;
    }
    @Mutation
    public setHeroImageFileName(data: string) {
        this.heroFileName = data;
    }

    @Mutation
    public setMeetingLogoImage(data: File | string) {
        this.meetingLogoImage = data;
    }

    @Mutation
    public setMeetingLogoFileName(data: string) {
        this.meetingLogoFileName = data;
    }

    @Mutation
    public setCompany(data: TradeshowCompanyObject) {
        this.companyData = data;
    }

    @Mutation
    public setCompanyUpdatePayload(data: TradeshowCompanyObject) {
        this.companyUpdatePayload = data;
    }

    @Mutation
    public setCompanyResetData(data: Partial<TradeshowCompanyObject>) {
        this.companyResetData = data;
    }

    @Action({ rawError: true })
    public setCompanyResourceImageForUpdate(options: {
        index: number;
        images: Record<string, string>;
    }) {
        // first
        this.resetCompanyUpdatePayload();

        // then
        const updatePayload = JSON.parse(
            JSON.stringify(this.companyUpdatePayload)
        );
        const resources = updatePayload.resources;

        if (Array.isArray(resources) && resources[options.index]) {
            resources[options.index]["image"] = options.images;
        }

        this.context.commit("setCompanyUpdatePayload", updatePayload);
    }

    @Action({ rawError: true })
    public initCompanyUpdatePayload() {
        const companyData = JSON.parse(JSON.stringify(this.companyData));

        const {
            brandColor,
            companyDescription,
            featuredDescription,
            companyName,
            featuredTitle,
            featuredVideo,
            address,
            social,
            mainPhone,
            websiteURL,
            resources,
            testimonials,
            images,
            participatingOrganizations,
            attendeeContacts
        } = companyData;

        let dataForReset = {
            brandColor,
            companyDescription,
            featuredDescription,
            companyName,
            featuredTitle,
            featuredVideo,
            address,
            social,
            mainPhone,
            websiteURL,
            resources,
            testimonials,
            images,
            participatingOrganizations,
            attendeeContacts
        };

        dataForReset = Vue.prototype.MgDefinedProps(dataForReset);

        let dataForUpdate = JSON.parse(
            JSON.stringify(this.companyUpdatePayload)
        );

        if (0 === Object.keys(dataForUpdate).length) {
            dataForUpdate = dataForReset;
        }

        if (false === Boolean("address" in dataForUpdate)) {
            dataForUpdate.address = {};
        }

        if (false === Boolean("images" in dataForUpdate)) {
            dataForUpdate.images = {
                logo: {
                    "800x800": "",
                    "400x400": ""
                },
                hero: {
                    "2000x368": "",
                    "800x147": ""
                },
                meetingLogo: {
                    "500x200": "",
                    "1000x400": ""
                }
            };
        }

        if (false === Boolean("social" in dataForUpdate)) {
            dataForUpdate.social = {};
        } else if (Array.isArray(dataForUpdate.social)) {
            dataForUpdate.social = {};
        }

        if (false === Boolean("resources" in dataForUpdate)) {
            dataForUpdate.resources = [];
        }

        if (false === Boolean("testimonials" in dataForUpdate)) {
            dataForUpdate.testimonials = [];
        }

        dataForUpdate.attendeeContacts = Array.isArray(
            dataForReset.attendeeContacts
        )
            ? dataForReset.attendeeContacts
            : [];

        this.context.commit("setCompanyResetData", dataForReset);
        this.context.commit("setCompanyUpdatePayload", dataForUpdate);
    }

    @Action({ rawError: true })
    public resetCompanyUpdatePayload() {
        this.context.commit("setCompanyUpdatePayload", {});
        this.initCompanyUpdatePayload();
        this.context.commit("setCompanyUpdatePayload", this.companyResetData);
    }

    @Action({ commit: "setCompany", rawError: true })
    getCompany(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${companyId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    putCompany(option: GetCompanyDataOptions) {
        const companyId = option.id;
        const token = this.context.rootGetters.idToken;
        /**
         * Only the main edit profile call should alter the contacts, which we have to get from
         * the selection store.
         */
        const alterContacts = option.alterContacts
            ? option.alterContacts
            : false;
        const payload = { ...this.companyUpdatePayload };

        const htmlFields = [
            "companyDescription",
            "featuredDescription",
            "participatingOrganizations"
        ];

        htmlFields.forEach((element) => {
            const x = element as keyof TradeshowCompanyObject;
            if (element in payload) {
                payload[x] = Vue.prototype.MgSanitize(payload[x]);
            }
        });

        if (alterContacts) {
            payload.attendeeContacts = attendeeSelectionStore.allIds;
        }

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`/${endpoint}/${companyId}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    const data = response.data;

                    // first
                    this.context.commit("setCompany", data);

                    // then
                    this.getCompany({ id: companyId, alterContacts: true });
                    // this.resetCompanyUpdatePayload();

                    return resolve(data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    //Company Logo Upload
    @Action({ rawError: true })
    async uploadLogoImageFile(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = {
            data: { uploadURL: "", imageURLs: {} }
        };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/companyLogo-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Company Logo put url!");
        }

        await this.context.dispatch("initCompanyUpdatePayload");

        try {
            const updateValue = JSON.parse(
                JSON.stringify(this.companyUpdatePayload)
            );
            updateValue.images.logo = putUrlResponse.data.imageURLs;
            this.context.commit("setCompanyUpdatePayload", updateValue);

            const options = { id: this.companyData.companyId };
            await this.context.dispatch("putCompany", options);
        } catch (error) {
            console.error(error, "Could not save company logo");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data.uploadURL,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
            putResponse;
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    @Action({ rawError: true })
    async uploadHeroImageFile(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = {
            data: { uploadURL: "", imageURLs: {} }
        };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/hero-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Hero image put url!");
        }

        await this.context.dispatch("initCompanyUpdatePayload");

        try {
            const updateValue = JSON.parse(
                JSON.stringify(this.companyUpdatePayload)
            );
            updateValue.images.hero = putUrlResponse.data.imageURLs;
            this.context.commit("setCompanyUpdatePayload", updateValue);

            const options = { id: this.companyData.companyId };
            await this.context.dispatch("putCompany", options);
        } catch (error) {
            console.error(error, "Could not save company hero image");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data.uploadURL,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
            putResponse;
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    @Action({ rawError: true })
    async uploadMeetingLogo(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = {
            data: { uploadURL: "", imageURLs: {} }
        };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/meeting-logo-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get meeting logo image put url!");
        }

        await this.context.dispatch("initCompanyUpdatePayload");

        try {
            const updateValue = JSON.parse(
                JSON.stringify(this.companyUpdatePayload)
            );
            updateValue.images.meetingLogo = putUrlResponse.data.imageURLs;
            this.context.commit("setCompanyUpdatePayload", updateValue);

            const options = { id: this.companyData.companyId };
            await this.context.dispatch("putCompany", options);
        } catch (error) {
            console.error(error, "Could not save company meetingLogo");
        }

        try {
            await axios.request({
                url: putUrlResponse.data.uploadURL,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    @Action({ rawError: true })
    async uploadResourceImage(options: GetCompanyDataOptions) {
        const companyId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = {
            data: { uploadURL: "", imageURLs: {} }
        };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${companyId}/resource-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get resource image put url!");
        }

        await this.context.dispatch("initCompanyUpdatePayload");

        try {
            await this.context.dispatch("setCompanyResourceImageForUpdate", {
                index: options.uploadImageIndex,
                images: putUrlResponse.data.imageURLs
            });

            const putOptions = { id: this.companyData.companyId };
            await this.context.dispatch("putCompany", putOptions);
        } catch (error) {
            console.error(error, "Could not save company resource image");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data.uploadURL,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
            putResponse;
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }
}
