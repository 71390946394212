




















































































































































































































































































































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";

import { TradeshowCompanyObject } from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import TextEditor from "@/components/shared/TextEditor.vue";
import AttendeeSearchSelector from "@/components/shared/AttendeeSearchSelector.vue";

import states from "@/assets/dictionaries/state-list";
import country from "@/assets/dictionaries/country-list";
import { resourceImageMap } from "@/assets/data/init-values";

import comapanyVuexModule from "@/store/vuex-modules/companies";
const companyStore = getModule(comapanyVuexModule);

import store from "@/store";

export default Vue.extend({
    data() {
        return {
            beLazy: true,
            value: null,
            isReady: false,

            states: states,
            countries: country,

            selectedState: [],

            isLoading: false,
            submitSucess: false,
            submitError: false,

            updatePayload: {} as Partial<TradeshowCompanyObject>,

            editorSettings: [["bold", "italic"], ["link"]],
            userLocale: ""
        };
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        customizedVideoRoom: {
            type: Boolean,
            default: true
        },
        initialAttendeeContacts: {
            type: Array,
            default: () => []
        },
        isPavilion: {
            type: Boolean,
            default: false
        }
    },
    components: { Spinners, TextEditor, AttendeeSearchSelector },

    /**
     * Computed
     */
    computed: {
        stateName(): Record<string, any> {
            const stateAbbrProperty = "alpha-2";
            return Object(this.states).map(
                (item: Record<string, string>) => item[stateAbbrProperty]
            );
        },

        descriptionLength(): number {
            const trimmedDesc = this.updatePayload.companyDescription
                ? this.updatePayload.companyDescription.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                  )
                : "";

            return trimmedDesc.length;
        },

        canSubmit(): boolean {
            return this.descriptionLength <= 2500;
        },

        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("tradeshow");
        },

        organizationLabel(): string {
            return this.pageOptions && this.pageOptions.organizationLabel
                ? this.pageOptions.organizationLabel
                : "Organization";
        },

        participantHelp(): string {
            return this.pageOptions && this.pageOptions.addParticipantsHelp
                ? this.pageOptions.addParticipantsHelp
                : "Users who are excluded from search will not appear here. If you know that a specific attendee is registered but not appearing, please check their profile and make sure they have not removed themselves from the search (setting in Edit Profile).";
        },

        shouldShowSelector(): boolean {
            return this.pageOptions &&
                this.pageOptions.useCompanyIdForParticipants
                ? false
                : true;
        }
    },

    /**
     * Lifecycle
     */
    created() {
        this.initUpdatePayload();

        eventHub.$on("company-update-started", this.handleUpdateStarted);
        eventHub.$on("company-update-success", this.handleUpdateSuccess);
        eventHub.$on("company-update-error", this.handleUpdateError);
        eventHub.$on("company-update-done", this.handleUpdateDone);

        this.userLocale =
            navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : navigator.language;
    },

    beforeDestroy() {
        this.resetSubmitFlags();
        this.setUpdatePayload();

        eventHub.$off("company-update-started", this.handleUpdateStarted);
        eventHub.$off("company-update-success", this.handleUpdateSuccess);
        eventHub.$off("company-update-error", this.handleUpdateError);
        eventHub.$off("company-update-done", this.handleUpdateDone);
    },
    methods: {
        /**
         * Methods
         */
        initUpdatePayload() {
            companyStore.initCompanyUpdatePayload();

            this.updatePayload = JSON.parse(
                JSON.stringify(companyStore.companyUpdatePayload)
            );
        },
        resetUpdatePayload() {
            companyStore.resetCompanyUpdatePayload();

            this.updatePayload = JSON.parse(
                JSON.stringify(companyStore.companyResetData)
            );
        },
        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSucess = false;
            this.submitError = false;
        },
        setUpdatePayload() {
            const payload = this.updatePayload;
            companyStore.setCompanyUpdatePayload(payload);
        },
        handleCancel() {
            this.resetUpdatePayload();
            this.$emit("cancel");
        },
        handleSubmit() {
            this.setUpdatePayload();
            this.$emit("submit");
        },
        handleUpdateStarted() {
            this.isLoading = true;
        },
        handleUpdateSuccess() {
            this.submitSucess = true;
        },
        handleUpdateError() {
            this.submitError = true;
        },
        handleUpdateDone() {
            this.isLoading = false;
        },

        addMoreResources() {
            const obj = {
                image: resourceImageMap,
                title: "",
                description: "",
                url: ""
            };
            if (Array.isArray(this.updatePayload.resources)) {
                this.updatePayload.resources.push(obj);
            }
        },

        removeResource(index: number) {
            if (Array.isArray(this.updatePayload.resources)) {
                this.updatePayload.resources.splice(index, 1);
            }
        }
    }
});
