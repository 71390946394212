var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_vm._l((_vm.companyResources),function(item,index){return _c('article',{key:((item.title) + "-" + index),staticClass:"flex flex-col md:flex-row py-10 border-b border-dividers last:border-0 border-opacity-50"},[(_vm.canMakeEdit || _vm.getSrc(item) || _vm.getSrcSet(item))?_c('div',{staticClass:"px-4 md:pl-0 mb-8 md:w-1/3 md:mb-0 flex md:justify-center"},[_c('figure',{staticClass:"relative"},[(index === _vm.loadingImageIndex)?_c('Spinners',{staticClass:"py-10"}):(_vm.getSrc(item) || _vm.getSrcSet(item))?_c('MgImage',{staticClass:"block lg:max-h-md",attrs:{"src":_vm.getSrc(item),"srcset":_vm.getSrcSet(item),"alt":""}}):_vm._e(),(_vm.canMakeEdit)?_c('b-tooltip',{staticClass:"absolute transform translate-x-1/2 -translate-y-1/2 right-0 top-0",attrs:{"label":"Remove image"}},[(_vm.getSrc(item) || _vm.getSrcSet(item))?_c('button',{staticClass:"button button-pill",on:{"click":function($event){return _vm.removeImage(index)}}},[_c('font-awesome-icon',{attrs:{"aria-hidden":"true","icon":"times"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Remove image")])],1):_vm._e()]):_vm._e(),(_vm.canMakeEdit)?_c('div',{class:{
                        'absolute transform -translate-x-1/2 left-half bottom-0 text-center flex': Boolean(
                            _vm.getSrc(item) || _vm.getSrcSet(item)
                        )
                    }},[_c('button',{staticClass:"button button-pill whitespace-nowrap",class:{
                            'mt-2': !Boolean(
                                _vm.getSrc(item) || _vm.getSrcSet(item)
                            )
                        },on:{"click":function($event){$event.preventDefault();return _vm.showImageModal($event, index)}}},[_c('font-awesome-icon',{staticClass:"mr-2 ",attrs:{"aria-hidden":"true","icon":"pen"}}),_c('span',[_vm._v(" "+_vm._s(Boolean(_vm.getSrc(item) || _vm.getSrcSet(item)) ? "Edit" : "Upload")+" image ")])],1)]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"px-4 md:w-3/4 flex flex-col justify-center items-start content",class:!item.image ? 'md:w-1/2' : ''},[_c('h3',{staticClass:" text-white font-body text-left"},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.description && item.description != '')?_c('p',{staticClass:"text-left text-white"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e(),(item.url)?_c('a',{staticClass:"button pill-button is-primary",attrs:{"href":item.url,"target":"_blank","rel":"noopener noreferrer","aria-label":("learn more about " + (item.title))}},[_vm._v(" Learn More ")]):_vm._e()])])}),_c('b-modal',{directives:[{name:"addbuefymodal",rawName:"v-addbuefymodal"}],attrs:{"active":_vm.isImageModalOpen,"trap-focus":"","width":700},on:{"update:active":function($event){_vm.isImageModalOpen=$event}}},[_c('ImageUploadForm',{on:{"cancel":_vm.closeImageModal,"submit":_vm.startFileUpload}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }