












































import Vue from "vue"
import store from "@/store"
import "swiper/css/swiper.css";
import { SwiperOptions } from "swiper";

interface Product {
    id: string;
    productFilename?: string;
    productImage?: string;
    productDescription?: string;
    productName?: string;
}

export default  Vue.extend({
    data() {
        return {
            activeProductIndex: 0,

            swiperOption: {
                direction: "vertical",
                slidesPerView: 3,
                spaceBetween: 20,
                mousewheel: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                }
            } as SwiperOptions
        }
    },
    props: {
        products: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    methods: {
        setActiveProduct(activeProductIndex: number) {
            this.activeProductIndex = activeProductIndex;
        }
    },
    computed: {
        showActiveProduct(): number | unknown {
            return this.products[this.activeProductIndex];
        },

        getLayoutOptions(): Record<string, string> {
            return store.getters.layoutOptions;
        }
    }
    
})
