








































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {
    TradeshowCompanyObject,
    TradeshowVideoObject,
    CompanyResourcesDataObject,
    ProductData,
    GetCompanyDataOptions,
    AttendeeDataObject
} from "@/types/interfaces";

import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import UpcomingStreams from "./UpcomingStreams.vue";
import FeaturedProducts from "./FeaturedProducts.vue";
import CompanyResources from "@/components/exhibitor-profile/sections/CompanyResources.vue";
// import SponsorResource from "./FeaturedProducts.vue";
import FeaturedSessions from "./FeaturedSessions.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";
import SessionScheduleItem from "@/components/sessions/SessionScheduleItem.vue";
import ExhibitorProfileEditForm from "@/components/exhibitor-profile/ExhibitorProfileEditForm.vue";
import ExhibitorLogoUpload from "@/components/exhibitor-profile/ExhibitorLogoUpload.vue";
import ExhibitorHeroUpload from "@/components/exhibitor-profile/ExhibitorHeroUpload.vue";
import ExhibitorMeetingLogoUpload from "@/components/exhibitor-profile/ExhibitorMeetingLogoUpload.vue";
import GeneralCommentsComponent from "@/components/shared/GeneralCommentComponent.vue";
import MgImage from "@/components/shared/MgImage.vue";

import FavoriteButton from "@/components/shared/FavoriteButton.vue";

//social media account
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";

import ComapanyModule from "@/store/vuex-modules/companies";
const companyStore = getModule(ComapanyModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

import store from "@/store";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

export default Vue.extend({
    data() {
        return {
            isLoading: true,
            isLoadingLogo: false,
            isLoadingHero: false,
            isLoadingMeetingLogo: false,
            istemporaryImage: false,
            isModalOpen: false,
            isLogoModalOpen: false,
            isHeroModalOpen: false,
            isMeetingLogoModalOpen: false,
            isLoadingFavorites: true,
            showEditControls: false,
            logoTimeout: -1,
            heroTimeout: -1,
            meetingLogoTimeout: -1
        };
    },
    components: {
        "upcoming-streams": UpcomingStreams,
        "featured-products": FeaturedProducts,
        "featured-sessions": FeaturedSessions,
        "attendee-card": AttendeeCard,
        "svg-facebook": svgFacebook,
        "svg-twitter": svgTwitter,
        "svg-instagram": svgInstagram,
        "svg-linkedin": svgLinkedin,
        // "sponsor-resource": SponsorResource,
        Spinners,
        ExhibitorProfileEditForm,
        ExhibitorLogoUpload,
        ExhibitorHeroUpload,
        CompanyResources,
        GeneralCommentsComponent,
        NotFound,
        SessionScheduleItem,
        ExhibitorMeetingLogoUpload,
        MgImage,
        FavoriteButton
    },
    directives: {
        addbuefymodal: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    props: {
        id: {
            type: String,
            default: "",
            required: true
        }
    },
    /**
     * Computed
     */
    computed: {
        isVirtual(): boolean{
             return Boolean(this.$store.getters?.isVirtual)
        },
        usesCreateActivityControl() {
            const optionChecker = store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        },
        userInfo(): Record<string, string> {
            return store.getters.userInfo;
        },

        exhibitorTier(): number {
            return parseInt(this.userInfo?.exhibitorTier);
        },

        exhibitorThresholdAddToProfile(): string {
            return store.state.settingsVuexModule
                .exhibitorThresholdAddToProfile;
        },

        exhibitorCanAddSessionToProfile(): boolean {
            const threshold: number = +this.exhibitorThresholdAddToProfile;
            const condition1 = Boolean(
                this.exhibitorTier && threshold <= this.exhibitorTier
            );

            return condition1;
        },

        deFaultFallbackHero(): string {
            let returnvalue = store.getters.getPageOptions("tradeshow")
                .defaultLogo;

            if (returnvalue) {
                returnvalue = `/bg/${returnvalue}`;
            } else {
                returnvalue = "";
            }

            return returnvalue;
        },

        featuredContentHeading(): string {
            let returnValue = "Upcoming live streams";

            if (this.exhibitorData && this.exhibitorData.featuredTitle) {
                returnValue = this.exhibitorData.featuredTitle;
            } else if (
                this.getConfigurableLabel &&
                this.getConfigurableLabel?.tradeshowExhibitor?.streamHeader
            ) {
                returnValue = this.getConfigurableLabel.tradeshowExhibitor
                    .streamHeader;
            }

            return returnValue;
        },

        layoutImagePagePrimary(): string {
            let returnvalue = store.getters.getPageOptions("tradeshow")
                .defaultHero;

            if (returnvalue) {
                returnvalue = `/bg/${returnvalue}`;
            } else {
                returnvalue = "";
            }

            return returnvalue;
        },

        companyName(): string {
            let returnValue = "";

            if (this.exhibitorData?.companyName) {
                returnValue = this.exhibitorData.companyName;
            } else return this.exhibitorData.name;
            return returnValue;
        },

        companyApiOptions(): GetCompanyDataOptions {
            const options = { id: this.id, alterContacts: true };
            return options;
        },
        companyLogoImage(): string | File {
            return companyStore.logoImage;
        },

        companyHeroImage(): string | File {
            return companyStore.heroImage;
        },

        heroImage(): string | File {
            let returnValue = "";
            const images = this.exhibitorData?.images?.hero;

            if (images && (images["800x147"] || images["2000x368"])) {
                if (images["2000x368"]) {
                    returnValue = images["2000x368"];
                } else {
                    returnValue = images["800x147"];
                }
            } else if (this.layoutImagePagePrimary) {
                returnValue = this.layoutImagePagePrimary;
            }

            return returnValue;
        },

        heroImageSrcset(): string | File {
            const exhibitor = this.exhibitorData;
            let returnPath = "";
            const set = [];

            if (exhibitor?.images?.hero) {
                const urls = exhibitor.images.hero;
                const imgSm = urls["800x147"];
                const imgLg = urls["2000x368"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },

        companyLogo(): string | File {
            const logo = this.exhibitorData?.images?.logo;
            let returnValue = "";

            // todo
            // this getter is for legacy support
            // delete after August 2021

            if ("string" === typeof logo) {
                returnValue = logo;
            }

            return returnValue;
        },

        companyLogoSrcSet(): string | File {
            const set = [];
            let returnValue = "";

            if (this.exhibitorData?.images?.logo) {
                const urls = this.exhibitorData.images.logo;
                const imgSm = urls["400x400"];
                const imgLg = urls["800x800"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }
                returnValue = set.join(",");
            }

            return returnValue;
        },

        meetingLogo(): string | File {
            const logo = this.exhibitorData?.images?.meetingLogo;
            let returnValue = "";

            if ("string" === typeof logo) {
                returnValue = logo;
            }

            return returnValue;
        },

        meetingLogoSrcset(): string | File {
            const set = [];
            let returnValue = "";

            if (this.exhibitorData?.images?.meetingLogo) {
                const urls = this.exhibitorData.images.meetingLogo;
                const imgSm = urls["500x200"];
                const imgLg = urls["1000x400"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }
                returnValue = set.join(",");
            }

            return returnValue;
        },

        meetingLogoImage(): string | File {
            return companyStore.meetingLogoImage;
        },

        usesLocalDataSearch(): boolean {
            return store.getters.usesLocalDataSearch;
        },

        getConfigurableLabel(): Record<string, any> {
            return store.getters.tradeshowLabel;
        },

        getLayoutOptions(): Record<string, string> {
            return store.getters.layoutOptions;
        },

        exhibitorData(): Record<string, any> {
            return companyStore.companyData;
        },

        companyEditor(): boolean {
            const user = store.getters.userInfo;
            return Boolean(user.exhibitorId === this.exhibitorData.companyId);
        },

        shouldShowEdit(): boolean {
            return this.canMakeEdit && this.showEditControls;
        },

        canMakeEdit(): boolean {
            return this.exhibitorData.canCurrentUserEdit;
        },

        companySessions(): Record<string, any> {
            return this.exhibitorData?.sessions || [];
        },
        companyResources(): Array<CompanyResourcesDataObject> {
            if (this.exhibitorData.resources) {
                return this.exhibitorData?.resources;
            } else {
                return [];
            }
        },

        companyAttendees(): Array<AttendeeDataObject> {
            return [...this.exhibitorData.exhibitor_attendees].sort(
                (a: AttendeeDataObject, b: AttendeeDataObject) => {
                    const aN = a.lastName ? a.lastName : "";
                    const bN = b.lastName ? b.lastName : "";
                    return aN.localeCompare(bN);
                }
            );
        },

        featuredProducts(): Array<ProductData> {
            const newObj: TradeshowCompanyObject = companyStore.companyData;
            if (newObj.products) {
                return newObj.products.filter((item: ProductData) => {
                    return item.productName != "";
                });
            }
            return [];
        },

        featuredResources(): Array<CompanyResourcesDataObject> {
            const newObj: TradeshowCompanyObject = companyStore.companyData;
            if (newObj.resources) {
                return newObj.resources.filter(
                    (item: CompanyResourcesDataObject) => {
                        return item.description != "";
                    }
                );
            }
            return [];
        },
        featuredCompanySession(): Array<TradeshowVideoObject> {
            const newObj: TradeshowCompanyObject = companyStore.companyData;
            if (newObj.videos) {
                return newObj.videos.filter((item: TradeshowVideoObject) => {
                    return item.url != "";
                });
            }
            return [];
        },

        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("tradeshow");
        },

        profileOptions(): Record<string, any> {
            return store.getters.getPageOptions("ExhibitorDetails") || {};
        },

        sectionHeaderText(): Record<string, string> {
            return {
                comments: this.pageOptions.content?.commentsHeader
                    ? this.pageOptions.content.commentsHeader
                    : "Comments",
                attendees: this.pageOptions.content?.attendeesHeader
                    ? this.pageOptions.content.attendeesHeader
                    : "Organization Participants",
                organizations: this.pageOptions.content?.organizationsHeader
                    ? this.pageOptions.content.organizationsHeader
                    : "Participating Organizations"
            };
        },

        cityStateZipCountry(): string {
            if (!this.exhibitorData || !this.exhibitorData.address) {
                return "";
            }

            const fields = [
                this.exhibitorData.address.city,
                this.exhibitorData.address.state,
                this.exhibitorData.address.zip,
                this.exhibitorData.address.country
            ];

            return fields.filter((field) => field && field != "").join(", ");
        },

        shouldShowAttendeeSection(): boolean {
            const hasAttendees =
                Boolean(
                    Array.isArray(this.companyAttendees) &&
                        this.companyAttendees.length
                ) || this.exhibitorData.participatingOrganizations;

            return !this.pageOptions.hideAttendeeList && hasAttendees;
        },

        shouldShowMeetingLogoEdit(): boolean {
            return this.pageOptions.customizedVideoRoom === false
                ? false
                : true;
        }
    },

    /**
     * Methods
     */
    methods: {
        openParentCompany(id: string) {
            this.$router.push(`/tradeshow/exhibitor-details/${id}`);
        },
        fetchCompany() {
            return companyStore.getCompany(this.companyApiOptions);
        },
        showModal() {
            this.isModalOpen = true;
        },
        showLogoModal() {
            this.isLogoModalOpen = true;
        },
        showMeetingLogoModal() {
            this.isMeetingLogoModalOpen = true;
        },
        closeMeetingLogoModal() {
            this.isMeetingLogoModalOpen = false;
        },
        showHeroModal() {
            this.isHeroModalOpen = true;
        },

        closeLogoModal() {
            this.isLogoModalOpen = false;
        },
        closeHeroModal() {
            this.isHeroModalOpen = false;
        },
        closeModal() {
            this.isModalOpen = false;
        },

        handleProfileEditSubmit() {
            this.isLoading = true;
            eventHub.$emit("company-update-started");
            companyStore
                .putCompany(this.companyApiOptions)
                .then(() => {
                    eventHub.$emit("company-update-success");
                })
                .catch(() => {
                    eventHub.$emit("company-update-error");
                })
                .finally(() => {
                    eventHub.$emit("company-update-done");
                    this.isLoading = false;
                });
        },
        startFileUpload() {
            this.isLoading = true;

            eventHub.$emit("company-logo-upload-started");

            this.handleFileUpload()
                .then(() => {
                    eventHub.$emit("company-logo-upload-success");
                })
                .catch(() => {
                    eventHub.$emit("company-logo-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("company-logo-upload-done");
                    this.isLoading = false;
                });
        },
        async handleFileUpload() {
            const options = {
                ...this.companyApiOptions,
                image: this.companyLogoImage
            };

            try {
                this.isLoadingLogo = true;
                await companyStore.uploadLogoImageFile(options);
                this.logoTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     * this is temp code until we can listen for image processing completion.
                     */
                    this.isLoadingLogo = false;
                }, 5000);
            } catch (error) {
                this.isLoadingLogo = false;
                throw new Error("Upload failed.");
            }
        },
        startHeroUpload() {
            this.isLoading = true;

            eventHub.$emit("exhibitor-hero-upload-started");

            this.handleHeroUpload()
                .then(() => {
                    eventHub.$emit("exhibitor-hero-upload-success");
                })
                .catch((error: any) => {
                    console.error(error);
                    eventHub.$emit("exhibitor-hero-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("exhibitor-hero-upload-done");
                    this.isLoading = false;
                });
        },
        async handleHeroUpload() {
            const options = {
                ...this.companyApiOptions,
                image: this.companyHeroImage
            };

            try {
                this.isLoadingHero = true;
                await companyStore.uploadHeroImageFile(options);
                this.heroTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     * this is temp code until we can listen for image processing completion.
                     */
                    this.isLoadingHero = false;
                }, 5000);
            } catch (error) {
                this.isLoadingHero = false;
                throw new Error((error as Error).message);
            }
        },

        startMeetingLogoUpload() {
            this.isLoading = true;

            eventHub.$emit("company-meeting-logo-upload-started");

            this.handleMeetingLogoUpload()
                .then(() => {
                    eventHub.$emit("company-meeting-logo-upload-success");
                })
                .catch(() => {
                    eventHub.$emit("company-meeting-logo-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("company-meeting-logo-upload-done");
                    this.isLoading = false;
                });
        },
        async handleMeetingLogoUpload() {
            const options = {
                ...this.companyApiOptions,
                image: this.meetingLogoImage
            };

            try {
                this.isLoadingMeetingLogo = true;
                await companyStore.uploadMeetingLogo(options);
                this.meetingLogoTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     * this is temp code until we can listen for image processing completion.
                     */
                    this.isLoadingMeetingLogo = false;
                }, 5000);
            } catch (error) {
                this.isLoadingMeetingLogo = false;
                throw new Error((error as Error).message);
            }
        },

        logPageVisit() {
            const sponsorName = this.exhibitorData?.companyName;
            const sponsorId = this.exhibitorData?.companyId;

            const logData = {
                type: `SponsorView`,
                sponsorName,
                sponsorId
            };

            store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        },

        toggleEditControls() {
            this.showEditControls = !this.showEditControls;
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        }
    },
    /**
     * Lifecycle
     */
    created() {
        const companyPromises = [
            this.fetchCompany(),
            attendeeScheduleStore.getFavoriteSessions(),
            attendeeStore.getAttendee({ id: this.userInfo.id })
        ];
        Promise.allSettled(companyPromises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;

            this.logPageVisit();
        });

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on("company-resource-removed", this.handleProfileEditSubmit);
    },

    beforeDestroy() {
        // window clean up
        clearTimeout(this.logoTimeout);
        clearTimeout(this.heroTimeout);
        clearTimeout(this.meetingLogoTimeout);

        // vue clean up
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off("company-resource-removed", this.handleProfileEditSubmit);

        companyStore.setCompanyUpdatePayload({});
    }
});
