






















































































































import Vue from "vue"
import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { getModule } from "vuex-module-decorators";

import {
    CompanyResourcesDataObject,
    TradeshowCompanyObject
} from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import ImageUploadForm from "@/components/shared/ImageUploadForm.vue";
import MgImage from "@/components/shared/MgImage.vue";

import imageVuexModule from "@/store/vuex-modules/image";
const imageStore = getModule(imageVuexModule);

import comapanyVuexModule from "@/store/vuex-modules/companies";
const companyStore = getModule(comapanyVuexModule);

import { resourceImageMap } from "@/assets/data/init-values";


export default Vue.extend ({
    data() {
        return {
            isLoading: false,
            loadingImageIndex: null as number | null,
            isImageModalOpen: false,
            uploadImageIndex: -1,
            imageTimeout: -1
        }
    },
    components: {
        Spinners,
        ImageUploadForm,
        MgImage
    },
    directives: {
        addbuefymodal: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    props: {
        data: {
            required: true,
            type: Object,
            default() {
                return {};
            }
        },
        canMakeEdit:{
            type: Boolean,
        }
    },
    computed: {
        /**
         * Computed
         */
        companyResources(): Array<CompanyResourcesDataObject> {
            let returnValue: Array<CompanyResourcesDataObject> = [];

            if (this.data.resources) {
                returnValue = this.data.resources.filter(
                    (item: CompanyResourcesDataObject) => item.title
                );
            }

            return returnValue;
        },

        imageFile() {
            return imageStore.imageFile;
        }
    },
    methods: {

        /**
         * Methods
         */
        resetUpdatePayload() {
            companyStore.resetCompanyUpdatePayload();
        },

        showImageModal(event: Event, index: number) {
            event;
            this.uploadImageIndex = index;
            this.isImageModalOpen = true;
        },

        closeImageModal() {
            this.isImageModalOpen = false;
        },

        startFileUpload() {
            this.isLoading = true;

            eventHub.$emit("image-file-upload-started");

            this.handleFileUpload()
                .then(() => {
                    eventHub.$emit("image-file-upload-success");
                })
                .catch(() => {
                    eventHub.$emit("image-file-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("image-file-upload-done");
                    this.isLoading = false;
                });
        },

        async removeImage(index: number) {
            await companyStore.setCompanyResourceImageForUpdate({
                index: index,
                images: resourceImageMap
            });

            eventHub.$emit("image-file-upload-success");
            eventHub.$emit("company-resource-removed");
        },

        async handleFileUpload() {
            const options = {
                id: this.data.companyId || "",
                image: this.imageFile,
                uploadImageIndex: this.uploadImageIndex
            };

            try {
                this.loadingImageIndex = this.uploadImageIndex;
                await companyStore.uploadResourceImage(options);
                this.imageTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     * this is temp code until we can listen for image processing completion.
                     */
                    this.loadingImageIndex = null;
                }, 5000);
            } catch (error) {
                this.loadingImageIndex = null;
                throw new Error("Upload failed.");
            }
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        },

        getSrc(item: CompanyResourcesDataObject) {
            const src = item.image;
            let returnValue = "";

            // todo
            // this getter is for legacy support
            // delete after october 2021
            if ("string" === typeof src) {
                returnValue = src;
            }

            return returnValue;
        },

        getSrcSet(item: CompanyResourcesDataObject) {
            const set = [];
            let returnValue = "";

            if (item.image) {
                const urls = item.image;
                const imgSm = urls["500x500"];
                const imgLg = urls["762x762"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnValue = set.join(",");
            }

            return returnValue;
        },
        /**
     * Lifecycle
     */
    created() {
            eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        },

        beforeDestroy() {
            eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);

            clearTimeout(this.imageTimeout);
        },
    }
})
